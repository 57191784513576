import { useAxios } from '@/plugins/axios'
import { User, UserUpdate } from './users'

const version = 'v1'
const resource = 'me'

export const useGetCurrentUser = () => {
  const axios = useAxios<User>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getCurrentUser = () => axios.exec({})

  return {
    ...axios,
    getCurrentUser,
  }
}

export const useUpdateCurrentUser = () => {
  const axios = useAxios<User>({
    method: 'put',
    url: `/${version}/${resource}`,
  })

  const updateCurrentUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    updateCurrentUser,
  }
}

export const useChangePassword = () => {
  const axios = useAxios<void>({
    method: 'put',
    url: `/${version}/${resource}/password`,
  })

  const changePassword = (newPassword: string, oldPassword: string) =>
    axios.exec({
      data: {
        password: newPassword,
        verification: oldPassword,
      },
    })

  return {
    ...axios,
    changePassword,
  }
}

import { Identifiable } from '@/api/interfaces/identifiable'
import { useNotify } from '@/store'
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { axiosInstance } from './axios'

const { addNotification } = useNotify()
export interface IAxiosError extends Omit<AxiosError, 'response'> {
  response: AxiosResponse
  userMessage: string
}

function computeTimeoutDuration(message: string): number {
  if (message.length <= 50) return 5000
  if (message.length <= 100) return 7000
  if (message.length <= 150) return 9000
  return 11000
}

function handleCatch(error: IAxiosError): void {
  const CLIENT_ERRORS_REGEX = /^4[0-9][0-9]$/

  const {
    status,
    data: { message },
  } = error.response

  if (CLIENT_ERRORS_REGEX.test(status.toString())) {
    addNotification({
      text: `${message}`,
      type: 'warning',
      timeout: computeTimeoutDuration(message),
    })
  } else {
    error.userMessage = 'Es ist ein unerwarteter Fehler aufgetreten.'
    throw error
  }
}
export class HateoasClient<T extends Identifiable, S> {
  instance: AxiosInstance = axiosInstance
  version: string
  resource: string
  basePath: string

  public constructor(version: string, resource: string) {
    this.version = version
    this.resource = resource
    this.basePath = `${version}/${resource}`
  }

  public async all(baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    try {
      const axiosResponse = await instance.get(this.basePath, { ...baseCfg })
      const items: T[] = axiosResponse.data?.content || []
      return {
        ...axiosResponse,
        items,
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  public async get(id: number, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    try {
      const axiosResponse = await instance.get(`${this.basePath}/${id}`, { ...baseCfg })
      const item: T = axiosResponse?.data as T
      return {
        ...axiosResponse,
        item,
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  public async create(data: S, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    try {
      const axiosResponse = await instance.post(this.basePath, data, baseCfg)
      const item: T | null = axiosResponse?.data as T | null
      return {
        ...axiosResponse,
        item,
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  public async update(id: number, data: T, baseCfg: AxiosRequestConfig = { method: 'PUT' }, instance = this.instance) {
    try {
      const axiosResponse = await instance.request({ ...baseCfg, url: `${this.basePath}/${id}`, data })
      const item: T | null = axiosResponse?.data as T | null
      return {
        ...axiosResponse,
        item,
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  public async delete(id: number, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    try {
      const axiosResponse = await instance.delete(`${this.basePath}/${id}`, { ...baseCfg })
      return {
        ...axiosResponse,
      }
    } catch (error) {
      handleCatch(error)
    }
  }
}

import { HateoasClient } from '@/plugins/hateoas-client'
import { AxiosRequestConfig } from 'axios'
import { DocumentInput, Document } from './interfaces/documents'

export class DocumentApi extends HateoasClient<Document, DocumentInput> {
  constructor() {
    super('v1', 'userDocuments')
  }

  public async downloadFile(id: number, filename: string, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    const axiosResponse = await instance.get(`${this.basePath}/${id}/file`, { ...baseCfg, responseType: 'blob' })
    const downloadUrl = window.URL.createObjectURL(new Blob([axiosResponse.data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', filename) //any other extension
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  public async uploadFile(document: DocumentInput, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    const formData = new FormData()
    Object.entries(document).forEach(([key, value]) => {
      if (key === 'file') {
        formData.append(key, value)
      } else {
        formData.append(key, String(value))
      }
    })
    const axiosResponse = await instance.post(`${this.basePath}`, formData, { ...baseCfg })
    return axiosResponse
  }
}

export const documentApi = new DocumentApi()

import { useAxios } from '@/plugins/axios'

const version = 'v1'
const resource = 'translations'

export interface Translation {
  key: string
  value: string
  locale: string
}

export interface TranslationGroup {
  key: string
  translations: Translation[]
}

export interface TranslationMap {
  [key: string]: Translation[]
}

export interface TranslationNestedMap {
  [key: string]: TranslationMap
}

export const useGetTranslations = () => {
  const axios = useAxios<TranslationMap>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getTranslations = () => axios.exec()

  return {
    ...axios,
    getTranslations,
  }
}

export const useGetLocales = () => {
  const axios = useAxios<TranslationNestedMap>({
    method: 'get',
    url: `/${version}/${resource}/locales`,
  })

  const getLocales = () => axios.exec()

  return {
    ...axios,
    getLocales,
  }
}

export const useUpdateTranslation = () => {
  const axios = useAxios<Translation>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const updateTranslation = (translation: Translation) => axios.exec({ data: translation })

  return {
    ...axios,
    updateTranslation,
  }
}

// export const useUpdateTranslationGroup = () => {
//   const axios = useAxios<Translation[]>({
//     method: 'put',
//     url: `/${version}/${resource}`,
//   })

//   const updateTranslationGroup = (group: TranslationGroup) => axios.exec({ data: role })

//   return {
//     ...axios,
//     updateTranslationGroup,
//   }
// }
// function updateTranslationGroup(group: TranslationGroup): Promise<Translation[]> {
//   return Promise.all(
//       group.translations.map(updateTranslation)
//   )
// }

export const useUploadTranslationsFile = () => {
  const axios = useAxios<void>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const uploadTranslationsFile = (file: File, locale: string) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('locale', locale)

    return axios.exec({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  return {
    ...axios,
    uploadTranslationsFile,
  }
}

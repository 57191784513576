import { HateoasClient } from '@/plugins/hateoas-client'
import { AxiosRequestConfig } from 'axios'
import { Project } from './interfaces'
import { ProjectDetails, ProjectInput } from './interfaces/projects'

export class ProjectApi extends HateoasClient<Project, ProjectInput> {
  constructor() {
    super('v1', 'projects')
  }

  public async getInfo(id: number, baseCfg: AxiosRequestConfig = {}, instance = this.instance) {
    const axiosResponse = await instance.get(`${this.basePath}/${id}/info`, { ...baseCfg })
    const item: ProjectDetails = axiosResponse?.data
    return {
      ...axiosResponse,
      item,
    }
  }
}

export const projectApi = new ProjectApi()

const emptyProject = (): Project => ({
  bookedHours: 0,
  budget: 0,
  clientId: 0,
  color: '',
  comment: '',
  from: '',
  hoursStepSize: 0,
  id: 0,
  name: '',
  parentId: null,
  parentName: null,
  projectManagerId: 0,
  projectManagerFullName: '',
  to: '',
  projectStatus: '',
  corrupt: false,
  corruptMessages: [],
})

const emptyProjectDetails = (): ProjectDetails => ({
  id: 0,
  bookedHours: 0,
  costs: 0,
  invoiced: 0,
  ordered: 0,
  paidNet: 0,
  revenue: 0,
})

export const useProjectApi = () => {
  return {
    projectApi,
    emptyProject,
    emptyProjectDetails,
  }
}

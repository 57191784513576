import { useAxios, useAxiosPaginated } from '@/plugins/axios'
import { PaginationParams } from '@/utils/pagination'

import { UserRight } from './rights'

const version = 'v1'
const resource = 'roles'

export interface NewUserRole<T = UserRight | string> {
  name: string
  description?: string
  rights: T[]
}

export interface UserRole<T> extends NewUserRole<T> {
  protected: boolean
  editable: boolean
  defaultRole: boolean
  systemRole: boolean
}

export const useGetRoles = () => {
  const axios = useAxiosPaginated<UserRole<UserRight>>({
    method: 'get',
    url: `/${version}/${resource}`,
  })

  const getRoles = (paginationParams?: PaginationParams) => axios.exec({ params: paginationParams })

  return {
    ...axios,
    getRoles,
  }
}

export const useGetRole = () => {
  const axios = useAxios<UserRole<UserRight>>({
    method: 'get',
  })

  const getRole = (roleName: string) => axios.exec({ url: `/${version}/${resource}/${roleName}` })

  return {
    ...axios,
    getRole,
  }
}
export const useCreateRole = () => {
  const axios = useAxios<UserRole<UserRight>>({
    method: 'post',
    url: `/${version}/${resource}`,
  })

  const createRole = (role: NewUserRole) => axios.exec({ data: role })

  return {
    ...axios,
    createRole,
  }
}
export const useUpdateRole = () => {
  const axios = useAxios<UserRole<UserRight>>({
    method: 'put',
  })

  const updateRole = (data: UserRole<string>) => axios.exec({ url: `/${version}/${resource}/${data.name}`, data })

  return {
    ...axios,
    updateRole,
  }
}
export const useDeleteRole = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteRole = (roleName: string) => axios.exec({ url: `/${version}/${resource}/${roleName}` })

  return {
    ...axios,
    deleteRole,
  }
}

import { HateoasClient } from '@/plugins/hateoas-client'
import { Plan, PlanInput } from '@/api/interfaces'
import { unref } from '@vue/composition-api'

export class PlanApi extends HateoasClient<Plan, PlanInput> {
  constructor() {
    super('v1', 'plans')
  }

  public async createPlansForMultipleUsers(plan: PlanInput, userIds: number[]): Promise<void> {
    if (userIds.length === 0) throw new Error('userIds not set')
    const promises: Promise<any>[] = []
    userIds.forEach((userId) => promises.push(planApi.create({ ...unref(plan), userId })))
    Promise.allSettled(promises).then((results) => {
      const rejected = results.filter((result) => result.status === 'rejected')
      console.log(rejected) // ['failed to fetch']
      return
    })
  }
}

export const planApi = new PlanApi()
